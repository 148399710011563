import gql from 'graphql-tag';

export const GET_NEW_USERS = gql`
  query getNewUsers {
    getNewUsers {
      id
      firstName
      email
      status
      countryId
      lastName
      emergenceContact
      personalEmail
      contactRelationship
      gender
      birthday
      createdAt
      employee {
        iban
        bankName
        NUIS
        managerId
        phoneNumber
        address
        notes
        CompanyBranchId
        manager {
          id
          firstName
          lastName
          email
        }
        companyBranch {
          id
          country
          city
        }
      }
      roles {
        id
        name
        permissions {
          leaveRequests
          timelogs
        }
      }
    }
  }
`;

export const GET_ROLE_PERMISSIONS = gql`
  query getRolePermissions($id: Int!) {
    role(roleId: $id) {
      permissions {
        invoices
        contracts
        roles
        clients
        timelogs
        finance
        analytics
        leaveRequests
        leaveRequestConfigurations
        users
        reports
        projects
        employees
        documents
        userAbsences
      }
    }
  }
`;
export const GET_ROLE_FOR_TAGS = gql`
  query getRolesForTags {
    rolesForTags {
      id
      name
    }
  }
`;
export const GET_ROLES_SIMPLE = gql`
  query getSimpleRoles {
    roles {
      id

      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles {
    roles {
      id
      permissions {
        invoices
        roles
        clients
        timelogs
        analytics
        leaveRequests
        leaveRequestConfigurations
        users
        reports
        projects
        employees
        documents
        userAbsences
      }
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_COMPANIES = gql`
  query getCompanies {
    companies {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($companyId: Int!) {
    company(companyId: $companyId) {
      id
      name
      permissions {
        clients
        invoices
        users
        projects
        reports
        timelogs
        leaveRequests
        roles
        analytics
        employees
      }
      createdAt
      updatedAt
      leaveRequestConfiguration {
        id
        maxDurationLeaveRequest
        daysLimitOneMonthInAdvance
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const GET_COMPANY_INFORMATION = gql`
  query getCompanyInformation {
    company {
      id
      name
      iban
      accountNumber
      logo
      nipt
      contactNumber
      createdAt
      updatedAt
    }
  }
`;
export const NEW_PASSWORD = gql`
  query newPassword($token: String!) {
    newPassword(token: $token)
  }
`;

export const GET_USER_PERMISSIONS = gql`
  query getPermissions {
    userPermissions {
      timelogs
      invoices
      projects
      clients
      reports
      finance
      users
      leaveRequests
      employees
      roles
      analytics
      contracts
      leaveRequestConfigurations
    }
  }
`;

export const GET_INVOICES = gql`
  query invoices($month: DateTimeISO) {
    invoices(month: $month) {
      id
      monthDate
      dueDate
      isPaid
      approvalStatus
      totalAmount
      createdAt
      timesheetAttachment
      otherAttachment
      ownerName
    }
  }
`;

export const GET_INVOICE_TOTAL_AMOUNT = gql`
  query getInvoiceTotalAmount($contractorId: Int) {
    getInvoiceTotalAmount(contractorId: $contractorId) {
      totalAmount
    }
  }
`;

export const GET_INVOICE_TOTAL_PAID_AMOUNT = gql`
  query getInvoiceTotalPaidAmount($contractorId: Int) {
    getInvoiceTotalPaidAmount(contractorId: $contractorId) {
      totalAmount
    }
  }
`;

export const GET_INVOICE_TOTAL_UNPAID_AMOUNT = gql`
  query getInvoiceTotalUnPaidAmount($contractorId: Int) {
    getInvoiceTotalUnPaidAmount(contractorId: $contractorId) {
      totalAmount
    }
  }
`;

export const GET_INVOICE_EMPLOYEE_TIMELOGS = gql`
  query employeeTimelogs($employeeId: Int!, $projectId: Int!, $month: DateTime!, $invoiceId: Int!) {
    invoiceTimelogs(employeeId: $employeeId, projectId: $projectId, month: $month, invoiceId: $invoiceId) {
      id
      description
      duration
      logDate
      projectId
      userId
      createdAt
      updatedAt
      tag {
        id
        name
      }
      deletedAt
      project {
        name
      }
      employee {
        User {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_INVOICE_DETAILS = gql`
  query invoice($id: Int!) {
    invoice(id: $id) {
      id
      monthDate
      dueDate
      totalAmount
      timesheetAttachment
      timesheetAttachmentInline
      otherAttachment
      InvoiceItems {
        Project {
          name
        }
        price
        quantity
        name
      }
    }
  }
`;

export const GET_INVOICE_ANALYTICS = gql`
  query analytics {
    getEarnings {
      lifeEarnings
      ytdEarnings
      lastMonthEarnings
    }
  }
`;

export const GET_EMPLOYEE_UTILIZATION = gql`
  query employeeUtilization($userId: Float!, $year: Int, $month: Int) {
    employee(userId: $userId) {
      utilization(year: $year, month: $month) {
        allWorkedHours
        utilizationRate
        totalPossibleHours
      }
      monthsWorked(year: $year) {
        name
        monthNumber
      }
      statistics(year: $year, month: $month) {
        projectName
        workedHours
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query countries {
    countries {
      id
      fullName
    }
  }
`;

export const GET_MANAGERS = gql`
  query managers {
    possibleHolidaysManagers {
      userId
      name
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjects {
    projects {
      id
      name
      description
      start
      end
      budget
      hourlyRate
      status
      createdAt
      billable
    }
  }
`;

export const GET_NEW_TIMELOGS = gql`
  query getTimelogs($limit: Int, $cursor: DateTimeISO, $employeeId: Int, $projectId: Int, $invoiceDate: DateTimeISO) {
    getTags {
      id
      name
    }
    getTimelogs(
      limit: $limit
      cursor: $cursor
      employeeId: $employeeId
      projectId: $projectId
      invoiceDate: $invoiceDate
    ) {
      id
      description
      duration
      logDate
      projectId
      tag {
        id
        name
      }
      userId
      createdAt
      updatedAt
      deletedAt
      project {
        id
        name
      }
      employee {
        User {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_REMINDER_STATUS = gql`
  query timelogReminder {
    employee {
      timelogReminder
    }
  }
`;

export const GET_TIMELOGS_NUMBER = gql`
  query timelogsCount {
    timelogsCount {
      timelogsNumber
    }
  }
`;

export const GET_CLIENTS = gql`
  query Clients {
    clients {
      name
      id
      invoiceDaysDue
      active
    }
  }
`;

export const GET_INVOICE_CLIENTS = gql`
  query InvoiceClients {
    invoiceClients {
      name
      id
      invoiceDaysDue
      active
    }
  }
`;

export const GET_INVOICE_COMPANY_EMPLOYEES = gql`
  query companyEmployees {
    companyEmployeesInvoice {
      name
      id
    }
  }
`;

export const GET_CLIENT_PROJECTS = gql`
  query clientProjects($userId: Float!) {
    clients(userId: $userId) {
      projects {
        id
        name
        description
        start
        end
        budget
        hourlyRate
        status
        createdAt
        billable
      }
    }
  }
`;

export const GET_EMPLOYEE_ASSIGNED_PROJECTS = gql`
  query ownProjects($userId: Float) {
    ownProjects(userId: $userId) {
      id
      name
      description
      start
      end
      budget
      hourlyRate
      status
      createdAt
      billable
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    User {
      id
      firstName
      lastName
      email
      paymentDueDateOffset
      employee {
        CompanyBranchId
        manager {
          firstName
          lastName
        }
        companyBranch {
          id
          country
          city
        }
      }
      roles {
        id
        name
        permissions {
          timelogs
          leaveRequests
        }
      }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query getUserProfile($userId: Float) {
    User(userId: $userId) {
      id
      firstName
      lastName
      countryId
      emergenceContact
      personalEmail
      contactRelationship
      gender
      birthday
      paymentDueDateOffset
      employee {
        iban
        bankName
        NUIS
        managerId
        phoneNumber
        address
        notes
        CompanyBranchId
        companyBranch {
          id
          country
          city
        }
        manager {
          id
          firstName
          lastName
          email
        }
      }
      status
      roles {
        id
        permissions {
          timelogs
          leaveRequests
        }
        name
      }
      email
      createdAt
    }
  }
`;

export const GET_SINGLE_EMPLOYEE = gql`
  query singleEmployee($userId: Float!) {
    employee(userId: $userId) {
      userId
      managerId
      User {
        Country {
          fullName
          id
        }
      }
    }
  }
`;

export const GET_PROJECTS_MINIFIED = gql`
  query getProjectsMinified {
    projects {
      id
      name
      status
    }
  }
`;

export const EMPLOYEE_GET_PROJECTS_MINIFIED = gql`
  query getEmployeProjectsMinified($userId: Float) {
    employee(userId: $userId) {
      projects {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_EMPLOYEES = gql`
  query getProjectEmployees($id: Int!) {
    Project(id: $id) {
      employeeAssignments {
        employee {
          User {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_PROJECT_EMPLOYEE_ASSIGNMENTS = gql`
  query getProjectEmployeeAssignments($id: Int!) {
    Project(id: $id) {
      employeeAssignments {
        id
        hourlyRate
        employee {
          User {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_ASSIGNMENT = gql`
  query clients($userId: Float!) {
    clients(userId: $userId) {
      userAssignments {
        id
        clientId
        userId
        User {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PROJECT_ASSIGNABLE_EMPLOYEES = gql`
  query getProjectAssignableEmployees($id: Int!) {
    Project(id: $id) {
      assignableEmployees {
        User {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PROJECT_ASSIGNABLE_CLIENTS = gql`
  query getProjectAssignableClients($clientId: Float!) {
    client(clientId: $clientId) {
      assignableClientUsers {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_EMPLOYEE_ASSIGNMENT = gql`
  query employeeAssignment($id: Int!) {
    employeeAssignment(id: $id) {
      id
      employeeId
      projectId
      status
      hourlyRate
      emailNotification
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const GET_USER_PROJECTS_ASSIGNED = gql`
  query getEmployeeProjectsAssigned($userId: Float) {
    employee(userId: $userId) {
      userId
      employeeAssignments {
        id
        employeeId
        projectId
      }
    }
  }
`;

export const GET_PROJECT_EMPLOYEES_MONTHS = gql`
  query getProjectEmployeesPerMonth($id: Int!) {
    Project(id: $id) {
      name
      timelogs {
        employee {
          User {
            id
            firstName
            lastName
          }
        }
        logDate
      }
    }
  }
`;

export const GET_EMPLOYEE_PROJECTS = gql`
  query getEmployeeProjects($userId: Float!) {
    employee(userId: $userId) {
      projects {
        id
        name
      }
    }
  }
`;

export const GET_PROJECTS_WORKED_MONTHS = gql`
  query getEmployeeProjectsWorked($userId: Float!, $projectId: Int!) {
    employee(userId: $userId) {
      timelogs(projectId: $projectId) {
        logDate
      }
    }
  }
`;

export const EMPLOYEE_PROJECT_REPORT = gql`
  query getEmployeeProjectReport($projectId: Int!) {
    Project(id: $projectId) {
      name
      timelogs {
        logDate
      }
    }
  }
`;

export const GET_ANALYTICS = gql`
  query analytics($startDate: DateTime, $endDate: DateTime, $clientId: Int) {
    analytics(startDate: $startDate, endDate: $endDate, clientId: $clientId) {
      date
      revenue
      billedHours
    }
  }
`;

export const GET_PROJECT_TAGS = gql`
  query getProjectTags($projectId: Int!) {
    projectTags(projectId: $projectId) {
      id
      name
      projectId
      project {
        id
        name
      }
    }
  }
`;

export const GET_TAGS = gql`
  query getTags {
    getTags {
      id
      name
      projectId
      project {
        id
        name
      }
    }
  }
`;

export const GET_REPORT_FILTERS = gql`
  query getReportFilters {
    getReportFilters {
      clients {
        id
        name
      }
      team {
        id
        name
      }
      projects {
        id
        name
      }
      tags {
        id
        name
      }
    }
  }
`;

export const GET_UTILIZATION_FILTERS = gql`
  query getReportUtilizationFilters {
    getReportFilters {
      team {
        id
        name
      }
    }
  }
`;

export const GET_REVENUE_FILTERS = gql`
  query getReportFilters {
    getReportFilters {
      team {
        id
        name
      }
      projects {
        id
        name
      }
    }
  }
`;

export const GET_TIMELOGS_FOR_REPORT = gql`
  query getTimelogsForReport(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $clients: [Int!]
    $team: [Int!]
    $billable: Boolean
    $projects: [Int!]
    $tags: [Int!]
    $limit: Int
    $cursor: DateTimeISO
  ) {
    getTimelogsForReport(
      startDate: $startDate
      endDate: $endDate
      clients: $clients
      team: $team
      billable: $billable
      projects: $projects
      tags: $tags
      limit: $limit
      cursor: $cursor
    ) {
      id
      tagName
      tagId
      description
      duration
      projectName
      reporter
      logDate
      createdAt
      updatedAt
    }
  }
`;

export const GET_REPORT_STATISTICS = gql`
  query getReportStatistics(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $clients: [Int!]
    $team: [Int!]
    $projects: [Int!]
    $billable: Boolean
    $tags: [Int!]
  ) {
    getReportStatistics(
      startDate: $startDate
      endDate: $endDate
      clients: $clients
      team: $team
      projects: $projects
      billable: $billable
      tags: $tags
    ) {
      totalTimelogsCount
      totalHours
    }
  }
`;

export const GET_REPORT_GRAPH = gql`
  query getReport(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $clients: [Int!]
    $team: [Int!]
    $projects: [Int!]
    $billable: Boolean
    $tags: [Int!]
  ) {
    getReport(
      startDate: $startDate
      endDate: $endDate
      clients: $clients
      team: $team
      projects: $projects
      billable: $billable
      tags: $tags
    ) {
      date
      week
      month
      totalHours
      totalBillableHours
    }
  }
`;

export const GET_BENCHMARKS = gql`
  query getBenchMarks {
    getBenchMarks {
      id
      month
      companyId
      updatedBy
      updatedByFullName
      previousPercentage
      percentage
    }
  }
`;

export const GET_MONTH_BENCHMARK = gql`
  query getMonthBenchmark($month: DateTimeISO!) {
    getMonthBenchmark(month: $month) {
      id
      month
      companyId
      updatedBy
      updatedByFullName
      previousPercentage
      percentage
    }
  }
`;

export const GET_UTILIZATION = gql`
  query getUtilization($startDate: DateTimeISO!, $endDate: DateTimeISO!, $team: [Int!]) {
    getUtilization(startDate: $startDate, endDate: $endDate, team: $team) {
      date
      week
      month
      percentage
      workedHours
      possibleWorkHours
    }
  }
`;

export const GET_INVOICE_STATISTICS_CHART = gql`
  query invoiceStatisticsChart($startDate: DateTimeISO, $endDate: DateTimeISO) {
    invoiceStatisticsChart(startDate: $startDate, endDate: $endDate) {
      date
      week
      month
      percentage
      workedHours
      possibleWorkHours
    }
  }
`;

export const EXPORT_TIMELOGS_REPORT = gql`
  query exportReport(
    $startDate: DateTimeISO!
    $endDate: DateTimeISO!
    $limit: Int
    $cursor: DateTimeISO
    $clients: [Int!]
    $team: [Int!]
    $billable: Boolean
    $projects: [Int!]
    $tags: [Int!]
    $reportType: String!
  ) {
    exportReport(
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      cursor: $cursor
      clients: $clients
      team: $team
      billable: $billable
      projects: $projects
      tags: $tags
      reportType: $reportType
    )
  }
`;

export const EXPORT_INVOICE_PDF = gql`
  query exportInvoicePDF($invoiceId: Int!) {
    exportInvoicePDF(invoiceId: $invoiceId)
  }
`;

export const EXPORT_INVOICE_CSV = gql`
  query exportInvoiceCSV($month: DateTime!) {
    exportInvoiceCSV(month: $month)
  }
`;

export const PREVIEW_INVOICE_EMPLOYEE_PROJECTS = gql`
  query previewInvoiceEmployeeProjects($month: DateTime!, $clientId: Int!, $contractorId: Int) {
    previewInvoiceEmployeeProjects(month: $month, clientId: $clientId, contractorId: $contractorId) {
      employeeId
      projectId
      rate
      duration
      billableValue
      employee {
        User {
          firstName
          lastName
        }
      }
      project {
        name
      }
    }
  }
`;

export const PREVIEW_INVOICE = gql`
  query previewInvoice($month: DateTime!, $clientId: Int!, $limit: Int, $cursor: DateTime) {
    previewInvoice(month: $month, clientId: $clientId, limit: $limit, cursor: $cursor) {
      id
      description
      duration
      logDate
      userId
      tag {
        id
        name
      }
      project {
        id
        name
      }
      employee {
        User {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const getEmployees = gql`
  query getEmployees {
    employees {
      userId
      User {
        id
        firstName
        lastName
      }
    }
  }
`;

export const TIMELOGS_BREAKDOWN_COUNT = gql`
  query timelogsBreakDownCount($month: DateTimeISO!, $clientId: Int!) {
    timelogsBreakDownCount(month: $month, clientId: $clientId)
  }
`;

export const MILESTONE_BREAKDOWN = gql`
  query getMilestoneBreakdown($milestoneId: Int!) {
    getMilestoneBreakdown(milestoneId: $milestoneId) {
      employeeId
      user {
        id
        firstName
        lastName
      }
      effort
    }
  }
`;

export const GET_BILLABLE_AND_UNBILLABLE_HOURS_PER_USER = gql`
  query getBillableAndUnbillableHoursPerUser($startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    getBillableAndUnbillableHoursPerUser(startDate: $startDate, endDate: $endDate) {
      duration
      billable
      id
      firstName
      lastName
    }
  }
`;
export const GET_CALCULATED_YEARLY_UTILIZATION_RATE = gql`
  query getCalculatedYearlyUtilizationRate($year: String!) {
    getCalculatedYearlyUtilizationRate(year: $year)
  }
`;

export const GET_CALCULATED_UTILIZATION_RATE_FOR_EACH_EMPLOYEE = gql`
  query getCalculatedUtilizationRateForEachEmployee($startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    getCalculatedUtilizationRateForEachEmployee(startDate: $startDate, endDate: $endDate) {
      key
      id
      employee
      billedHours
      nonBilledHours
      utilizationRate
      availableHours
    }
  }
`;

export const GENERATE_MONTHLY_AND_YEARLY_UTILIZATION_RATE_IN_PDF_FORMAT = gql`
  query generateMonthlyAndYearlyUtilizationRateInPDFFormat($startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    generateMonthlyAndYearlyUtilizationRateInPDFFormat(startDate: $startDate, endDate: $endDate)
  }
`;
