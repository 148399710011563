import React, { useState } from "react";
import { Col, Divider, Modal, Row, Button, Icon } from "antd";
import Text from "antd/lib/typography/Text";
import { TFunction } from "i18next";
import InvoiceItemsTable from "./invoiceItemsTable";
import moment from "moment";
import DownloadInvoiceS3 from "../downloadInvoiceS3";

interface IProps {
  t: TFunction;
  isVisible: boolean;
  setInvoiceDetailsVisibility: (value: boolean) => void;
  invoice: any;
}

const InvoiceDetails: React.FC<IProps> = ({ t, invoice, isVisible, setInvoiceDetailsVisibility }) => {
  const formatInvoiceDetails = () => {
    return invoice.InvoiceItems.map((item) => {
      return {
        hours: item.quantity,
        hourlyRate: item.price,
        description: item.name,
        project: {
          key: item.Project.id,
          label: item.Project.name,
        },
      };
    });
  };

  const calculateTotalPrice = () => {
    const invoiceItems = formatInvoiceDetails();
    const price = invoiceItems.reduce((price, item) => {
      return (price += item.hours * item.hourlyRate);
    }, 0);
    return `${price.toFixed(2)} €`;
  };

  return (
    <Modal
      width={"100%"}
      visible={isVisible}
      maskClosable={false}
      title={t("timelogs.CREATE_MODAL.CREATE_INVOICE")}
      className="createInvoiceModal"
      onCancel={() => setInvoiceDetailsVisibility(!isVisible)}
      footer={[]}>
      <InvoiceItemsTable t={t} invoice={invoice ? formatInvoiceDetails() : []} userId={0} getTotalPrice={() => null} />
      <Divider />
      <Row type="flex" justify="space-between">
        <Col span={6}>
          <Row>
            <Text>Month:</Text>
          </Row>
          <Row>
            <Text style={{ fontSize: "20px", fontWeight: "bolder" }}>
              {moment(invoice?.monthDate).format("MMMM YYYY")}
            </Text>
          </Row>
        </Col>
        <Col span={6}>
          <Row type="flex" justify="end">
            <Text>Total</Text>
          </Row>
          <Row type="flex" justify="end">
            <Text style={{ fontSize: "30px", fontWeight: "bold" }}>{invoice && calculateTotalPrice()}</Text>
          </Row>
        </Col>
      </Row>
      <Row>
        <Row>
          <Text>Timesheet Attachment:</Text>
        </Row>
        <Row style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <DownloadInvoiceS3 documentUrl={invoice?.timesheetAttachment} />
          <DownloadInvoiceS3 documentUrl={invoice?.timesheetAttachmentInline} openInNewTab={true} iconType='eye' />
        </Row>
      </Row>
      {invoice?.otherAttachment && (
        <Row style={{ marginTop: "15px" }}>
          <Row>
            <Text>Other Attachment:</Text>
          </Row>
          <Row>
            <DownloadInvoiceS3 documentUrl={invoice?.otherAttachment} />
          </Row>
        </Row>
      )}
      <Row>
        <Row style={{ marginTop: "15px" }}>
          <Text>Due Date:</Text>
        </Row>
        <Row>
          <Text style={{ fontSize: "20px", fontWeight: "bolder" }}>
            {moment(invoice?.dueDate).format("DD MMMM YYYY")}
          </Text>
        </Row>
      </Row>
    </Modal>
  );
};

export default InvoiceDetails;
